<template>
	<div class="home">
		<table-regionalhead></table-regionalhead>
	</div>
</template>

<script>
import tableRegionalhead from '@/components/pages/maint/regionalhead/tableRegionalhead.vue';

export default {
	components: {
		'table-regionalhead': tableRegionalhead,
	}
};
</script>

<style>

</style>

