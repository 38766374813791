<template>
    <v-dialog v-model="dialog" persistent max-width="60%" scrollable>
     <!-- <app-loader v-model="isLoading"></app-loader> -->
      <template v-slot:activator="{ on }">
          <app-add-button v-bind:module="'regionalhead'"></app-add-button>
        </template>
           <v-form
            ref="form"
            v-model="isvalid"
            lazy-validation
          >
           <v-card title>
             <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
            <v-card-text>
              <v-container grid-list-md>
                <v-layout wrap>
                  <v-flex xs12 sm6 md6>
                    <!-- <v-text-field v-model="editedItem.cur_code" required label="Regional Head Code"></v-text-field> -->
                    <v-text-field 
                        v-model="editedItem.rh_code"
                        :counter="2"
                        label="RH Code"
                        required
                        maxlength=2
                    ></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6 md6>
                    <!-- <v-text-field v-model="editedItem.rh_name" required label="Regional Head Name"></v-text-field> -->
                    <v-text-field 
                        v-model="editedItem.rh_name"
                        :counter="250"
                        label="Name"
                        required
                        maxlength=250
                    ></v-text-field>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue" dark @click="save">Save</v-btn>
              <v-btn color="red" text @click="close">Cancel</v-btn>
            </v-card-actions>
          </v-card>
          </v-form>
        </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue
	},
	data: () => ({
		search: null,
		isvalid: true,
		rh_codeRules: [
			v => !!v || 'RH Code is required',
			v => (v && v.length <= 7) || 'RH Code must be less than 3 characters',
		],
		rh_nameRules: [
			v => !!v || 'RH Name',
			v => (v && v.length <= 250) || 'RH Name must be less than 250 characters',
		],
	}),

	computed: {
		...mapGetters({
			regionalheads: 'regionalhead/regionalheads',  
			rhCode: 'regionalhead/rhCode',
			rhName: 'regionalhead/rhName',
			editedItem: 'regionalhead/editedItem',
			defaultItem: 'regionalhead/defaultItem',
			dialog: 'regionalhead/dialog',
			editedIndex: 'regionalhead/editedIndex',
			formTitle: 'regionalhead/formTitle',
			valid: 'regionalhead/valid',
			currUser: 'auth/currUser',
			isLoading: 'app/isLoading'
		}),
	}, 
	methods: {
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('regionalhead/setValid',true);
				this.$store.dispatch('regionalhead/setDialog',false);
				this.$store.dispatch('regionalhead/setEditedIndex','-1');
				this.isvalid = false;
			}, 2000);
		},
		save () {
			this.$store.dispatch('regionalhead/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			this.editedItem.edit_by = this.currUser.id;
			delete this.editedItem['updated_at'];
			delete this.editedItem['created_at'];
			if(this.valid){
				if (this.editedIndex > -1) {
					this.$store.dispatch('regionalhead/updateRegionalhead',this.editedItem);
				} else {
					this.$store.dispatch('regionalhead/saveRegionalhead',this.editedItem);
				}
				setTimeout(() => {
					this.close();
				}, 3000);
			}
		},
      
	}
};
</script>