<template>
  <div>
   
  <v-data-table
    :headers="headers"
    :items="regionalheads.data"
    sort-by="rh_name"
    class="elevation-1"
    dense
    :search="search"
	:items-per-page="1000"
	hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Regional Head File Maintenance</v-toolbar-title>
       <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
		<vue-excel-xlsx
			:data="regionalheads.data"
			:columns="columnHeaders"
			:file-name="module_name"
			>
			<v-btn icon alt class="mt-2" :color="regionalheads.data.length=== 0 ? 'gray' : 'primary'" :disabled="regionalheads.data.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
		</vue-excel-xlsx>
      </v-toolbar>
    </template>
    <template v-slot:item.action="{ item }">
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 3"
				>
					mdi-pencil-off
				</v-icon>
				<v-icon
					color="primary"
					v-else
					class="mr-2"
					small
					@click="editItem(item)"
				>
					edit
				</v-icon>
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 4"
				>
					mdi-delete-off
				</v-icon>
				<v-icon
					small
					v-else
					color="red"
					@click="deleteItem(item)"
				>
					delete
				</v-icon>
			</template>
			<template v-slot:body.append>
					<tr>
						<td colspan="8">
							<v-layout>
								<v-spacer></v-spacer>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											:disabled="parseInt(regionalheads.current_page)==1"
											@click="changePrevData"
											class="ma-2"
											text
											icon
										>
											<v-icon :disabled="parseInt(regionalheads.current_page)==1">mdi-chevron-left</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
								<v-flex md2 style="padding-top:15px;text-align: center">
									<p class="font-weight-light text--primary body-2">
										Page {{regionalheads.current_page}} ( {{regionalheads.from}} - {{regionalheads.to}} )
									</p>
								</v-flex>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											@click="changeNextData"
											class="ma-2"
											:disabled="!regionalheads.next_page_url"
											text
											icon
										>
											<v-icon :disabled="!regionalheads.next_page_url">mdi-chevron-right</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</td>
					</tr>
			</template>
  </v-data-table>
  <create-regionalhead  v-if="role_access > 1"></create-regionalhead>
  </div>
</template>

<script>
import createRegionalhead from './createRegionalhead.vue';
import { mapGetters } from 'vuex';


export default {
	components: {
		'create-regionalhead': createRegionalhead
	},
	data(){
		return{
			module_name: 'Regional Head',
			role_access: 1,
			search: ''
		};
	},
	computed: {
		...mapGetters({
			regionalheads: 'regionalhead/regionalheads',
			headers: 'regionalhead/headers',
			columnHeaders: 'regionalhead/columnHeaders',
			editedItem: 'regionalhead/editedItem',
			defaultItem: 'regionalhead/defaultItem',
			dialog: 'regionalhead/dialog',
			editedIndex: 'regionalhead/editedIndex',
			valid: 'regionalhead/valid',
			currUser: 'auth/currUser'
		}),
	},
	mounted () {
		this.$store.dispatch('regionalhead/getRegionalheads');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		editItem (item) {
			this.$store.dispatch('regionalhead/setEditedIndex',this.regionalheads.data.indexOf(item));
			this.$store.dispatch('regionalhead/setEditedItems',Object.assign({}, item));
			this.$store.dispatch('regionalhead/setDialog',true);
			this.$store.dispatch('regionalhead/setValid',true);
			this.$store.dispatch('regionalhead/setformTitle','Edit Entry');
		},
		deleteItem (item) {
			this.$swal.fire({
				title: 'Delete ' + item.rh_code + '(' + item.rh_name + ')' + '?',
				text: 'You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then((result) => {
				if (result.value) {
					this.$store.dispatch('regionalhead/setisLoading',true);
					this.$store.dispatch('regionalhead/deleteRegionalhead',item);
				}
			});
		},
		changeNextData() {
			var nextPage = this.regionalheads.current_page + 1;
			this.$store.dispatch('regionalhead/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.regionalheads.current_page - 1;
			this.$store.dispatch('regionalhead/getDataPages', prevPage);
		}
	},
};
</script>